import { useCallback, useInsertionEffect, useRef } from 'react';

/**
 * Hook to wrap callback in ref so it doesn't trigger re-renders and remains up-to-date
 * Implementation of https://github.com/reactjs/rfcs/pull/220
 *
 * refs:
 * bluesky: https://github.com/bluesky-social/social-app/blob/main/src/lib/hooks/useNonReactiveCallback.ts
 * react-aria: https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/utils/src/useEffectEvent.ts
 * radix-ui: https://github.com/radix-ui/primitives/blob/main/packages/react/use-callback-ref/src/useCallbackRef.tsx
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useCallbackRef<T extends (...args: any[]) => any>(
  callback?: T,
): T {
  const callbackRef = useRef(callback);

  useInsertionEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return useCallback(((...args) => callbackRef.current?.(...args)) as T, []);
}
